import { Link } from '@tanstack/react-router'

import { ShieldMinus } from 'lucide-react'

import { cn } from '@/lib/utils'

import { Button } from '../ui/button'

type UnauthorizedContentProps = {
	className?: string
}

/**
 * Generic unauthorized content component
 */
export const UnauthorizedContent = ({
	className,
}: UnauthorizedContentProps) => {
	return (
		<div
			className={cn(
				'mx-auto flex w-1/2 flex-col items-center justify-center rounded-lg bg-foreground p-12',
				className,
			)}
		>
			<ShieldMinus className="h-12 w-12 text-red-500" />
			<h2 className="my-4 text-2xl font-bold">Unauthorized</h2>

			<p className="text-center text-text-primary">
				You don't have permission to access this page. If you believe this is an
				error, please contact your administrator.
			</p>

			<Button variant="outline" asChild className="my-2">
				<Link to="/graylog">Go home</Link>
			</Button>
		</div>
	)
}

export default UnauthorizedContent
