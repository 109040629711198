import { useMemo } from 'react'
import isEqual from 'react-fast-compare'

import { getUserInitials } from '@/features/user/utils'

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'

import type { User } from '@/features/user/types/api'

export function UserAvatar({ user, avatar }: { user: User; avatar: string }) {
	const userInitials = useMemo(() => getUserInitials(user), [isEqual])

	return (
		<Avatar className="h-8 w-8 rounded-lg">
			<AvatarImage src={avatar} alt={user.fname} />
			<AvatarFallback className="rounded-lg">{userInitials}</AvatarFallback>
		</Avatar>
	)
}
