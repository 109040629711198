import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { integrationQueries } from '@/features/integrations/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useTitle } from '@/hooks/useTitle'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import UnauthorizedContent from '@/components/environment/unauthorized-content'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const IntegrationDetails = lazy(
	() => import('@/features/integrations/components/details'),
)

export const Route = createFileRoute('/_dashboard/integrations/$id')({
	beforeLoad: () => roleAuthGuard(Roles.enum.SystemManager),

	loader: async ({ params, context: { queryClient } }) => {
		const { id } = params

		const tenant = getCurrentTenant() as string

		await queryClient.ensureQueryData(integrationQueries.detail(tenant, id))
	},

	component: RouteComponent,
	pendingComponent: IntegrationsLoadingPage,
	errorComponent: IntegrationsErrorPage,
})

function RouteComponent() {
	useTitle('Integration Details')
	const { id } = Route.useParams()

	return (
		<ErrorBoundary
			fallback={({ resetError }) => (
				<ErrorBoundaryComponent resetError={resetError} />
			)}
		>
			<Suspense
				fallback={<Skeleton className="h-[200px] w-full max-w-screen-md" />}
			>
				<IntegrationDetails id={id} />
			</Suspense>
		</ErrorBoundary>
	)
}
function IntegrationsErrorPage({ error, reset }: ErrorComponentProps) {
	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}

function IntegrationsLoadingPage() {
	return <Skeleton className="h-[200px] w-full max-w-screen-md" />
}
