import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import type { ClassValue } from 'clsx'
import type { XiorError } from 'xior'

/**
 * Helper function to merge tailwind classes
 * @param {ClassValue[]} inputs - classes to merge
 * @returns
 */
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

/**
 * Helper function to delay execution
 * @param {number} ms - milliseconds to wait
 * @returns
 */
export function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Helper function to convert time to milliseconds
 * @param amount - The amount of time
 * @param unit - The unit of time
 * @returns The time in milliseconds
 */
export function time(amount: number, unit: 's' | 'm' | 'h' | 'd') {
	if (unit === 's') return amount * 1000
	if (unit === 'm') return amount * 60 * 1000
	if (unit === 'h') return amount * 60 * 60 * 1000
	if (unit === 'd') return amount * 24 * 60 * 60 * 1000

	return 0
}

/**
 * Retry query 3 times if the error is not a 404 or 401
 * @param failureCount - The number of times the query has been retried
 * @param error - The error object
 * @returns - Whether to retry the query
 */
export function defaultQueryRetry(
	failureCount: number,
	error: Error,
	maxRetries: number = 3,
) {
	const err = error as XiorError

	// Only retry if the error is not a 4XX or 5XX
	if (err.response?.status && err.response?.status > 400) {
		return false
	}

	// Only retry maxRetries times
	if (failureCount > maxRetries) {
		return false
	}

	return true
}
