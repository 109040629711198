/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as DashboardImport } from './routes/_dashboard'
import { Route as AuthImport } from './routes/_auth'
import { Route as DashboardIndexImport } from './routes/_dashboard/index'
import { Route as DashboardSettingsImport } from './routes/_dashboard/settings'
import { Route as DashboardReportImport } from './routes/_dashboard/report'
import { Route as DashboardIntegrationsImport } from './routes/_dashboard/integrations'
import { Route as DashboardGraylogImport } from './routes/_dashboard/graylog'
import { Route as AuthLogoutImport } from './routes/_auth/logout'
import { Route as AuthLoginImport } from './routes/_auth/login'
import { Route as AuthForgotPasswordImport } from './routes/_auth/forgot-password'
import { Route as DashboardUnauthorizedIndexImport } from './routes/_dashboard/unauthorized.index'
import { Route as DashboardSettingsIndexImport } from './routes/_dashboard/settings/index'
import { Route as DashboardPlaybooksIndexImport } from './routes/_dashboard/playbooks.index'
import { Route as DashboardIntegrationsIndexImport } from './routes/_dashboard/integrations/index'
import { Route as DashboardGraylogIndexImport } from './routes/_dashboard/graylog/index'
import { Route as DashboardActivityIndexImport } from './routes/_dashboard/activity.index'
import { Route as DashboardActionsIndexImport } from './routes/_dashboard/actions.index'
import { Route as DashboardSettingsTermDatesImport } from './routes/_dashboard/settings/term-dates'
import { Route as DashboardSettingsProfileImport } from './routes/_dashboard/settings/profile'
import { Route as DashboardSettingsNotificationsImport } from './routes/_dashboard/settings/notifications'
import { Route as DashboardPlaybooksIdImport } from './routes/_dashboard/playbooks.$id'
import { Route as DashboardIntegrationsIdImport } from './routes/_dashboard/integrations/$id'
import { Route as DashboardGraylogAlertsImport } from './routes/_dashboard/graylog/alerts'
import { Route as DashboardSettingsTenantSettingsIndexImport } from './routes/_dashboard/settings/tenant-settings.index'
import { Route as DashboardSettingsContactDirectoryIndexImport } from './routes/_dashboard/settings/contact-directory.index'
import { Route as DashboardIntegrationsNewIndexImport } from './routes/_dashboard/integrations/new.index'
import { Route as DashboardGraylogEventDefinitionsIndexImport } from './routes/_dashboard/graylog/event-definitions.index'
import { Route as DashboardGraylogAutomationSettingsIndexImport } from './routes/_dashboard/graylog/automation-settings.index'
import { Route as DashboardIntegrationsNewIdImport } from './routes/_dashboard/integrations/new.$id'
import { Route as DashboardGraylogAutomationSettingsRuleIdImport } from './routes/_dashboard/graylog/automation-settings.$ruleId'
import { Route as DashboardGraylogAutomationSettingsNewIndexImport } from './routes/_dashboard/graylog/automation-settings.new.index'

// Create/Update Routes

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const DashboardIndexRoute = DashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardSettingsRoute = DashboardSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardReportRoute = DashboardReportImport.update({
  id: '/report',
  path: '/report',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardIntegrationsRoute = DashboardIntegrationsImport.update({
  id: '/integrations',
  path: '/integrations',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardGraylogRoute = DashboardGraylogImport.update({
  id: '/graylog',
  path: '/graylog',
  getParentRoute: () => DashboardRoute,
} as any)

const AuthLogoutRoute = AuthLogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => AuthRoute,
} as any)

const DashboardUnauthorizedIndexRoute = DashboardUnauthorizedIndexImport.update(
  {
    id: '/unauthorized/',
    path: '/unauthorized/',
    getParentRoute: () => DashboardRoute,
  } as any,
)

const DashboardSettingsIndexRoute = DashboardSettingsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardSettingsRoute,
} as any)

const DashboardPlaybooksIndexRoute = DashboardPlaybooksIndexImport.update({
  id: '/playbooks/',
  path: '/playbooks/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardIntegrationsIndexRoute = DashboardIntegrationsIndexImport.update(
  {
    id: '/',
    path: '/',
    getParentRoute: () => DashboardIntegrationsRoute,
  } as any,
)

const DashboardGraylogIndexRoute = DashboardGraylogIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardGraylogRoute,
} as any)

const DashboardActivityIndexRoute = DashboardActivityIndexImport.update({
  id: '/activity/',
  path: '/activity/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardActionsIndexRoute = DashboardActionsIndexImport.update({
  id: '/actions/',
  path: '/actions/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardSettingsTermDatesRoute = DashboardSettingsTermDatesImport.update(
  {
    id: '/term-dates',
    path: '/term-dates',
    getParentRoute: () => DashboardSettingsRoute,
  } as any,
)

const DashboardSettingsProfileRoute = DashboardSettingsProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => DashboardSettingsRoute,
} as any)

const DashboardSettingsNotificationsRoute =
  DashboardSettingsNotificationsImport.update({
    id: '/notifications',
    path: '/notifications',
    getParentRoute: () => DashboardSettingsRoute,
  } as any)

const DashboardPlaybooksIdRoute = DashboardPlaybooksIdImport.update({
  id: '/playbooks/$id',
  path: '/playbooks/$id',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardIntegrationsIdRoute = DashboardIntegrationsIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => DashboardIntegrationsRoute,
} as any)

const DashboardGraylogAlertsRoute = DashboardGraylogAlertsImport.update({
  id: '/alerts',
  path: '/alerts',
  getParentRoute: () => DashboardGraylogRoute,
} as any)

const DashboardSettingsTenantSettingsIndexRoute =
  DashboardSettingsTenantSettingsIndexImport.update({
    id: '/tenant-settings/',
    path: '/tenant-settings/',
    getParentRoute: () => DashboardSettingsRoute,
  } as any)

const DashboardSettingsContactDirectoryIndexRoute =
  DashboardSettingsContactDirectoryIndexImport.update({
    id: '/contact-directory/',
    path: '/contact-directory/',
    getParentRoute: () => DashboardSettingsRoute,
  } as any)

const DashboardIntegrationsNewIndexRoute =
  DashboardIntegrationsNewIndexImport.update({
    id: '/new/',
    path: '/new/',
    getParentRoute: () => DashboardIntegrationsRoute,
  } as any)

const DashboardGraylogEventDefinitionsIndexRoute =
  DashboardGraylogEventDefinitionsIndexImport.update({
    id: '/event-definitions/',
    path: '/event-definitions/',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

const DashboardGraylogAutomationSettingsIndexRoute =
  DashboardGraylogAutomationSettingsIndexImport.update({
    id: '/automation-settings/',
    path: '/automation-settings/',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

const DashboardIntegrationsNewIdRoute = DashboardIntegrationsNewIdImport.update(
  {
    id: '/new/$id',
    path: '/new/$id',
    getParentRoute: () => DashboardIntegrationsRoute,
  } as any,
)

const DashboardGraylogAutomationSettingsRuleIdRoute =
  DashboardGraylogAutomationSettingsRuleIdImport.update({
    id: '/automation-settings/$ruleId',
    path: '/automation-settings/$ruleId',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

const DashboardGraylogAutomationSettingsNewIndexRoute =
  DashboardGraylogAutomationSettingsNewIndexImport.update({
    id: '/automation-settings/new/',
    path: '/automation-settings/new/',
    getParentRoute: () => DashboardGraylogRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/_auth/forgot-password': {
      id: '/_auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/logout': {
      id: '/_auth/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof AuthLogoutImport
      parentRoute: typeof AuthImport
    }
    '/_dashboard/graylog': {
      id: '/_dashboard/graylog'
      path: '/graylog'
      fullPath: '/graylog'
      preLoaderRoute: typeof DashboardGraylogImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/integrations': {
      id: '/_dashboard/integrations'
      path: '/integrations'
      fullPath: '/integrations'
      preLoaderRoute: typeof DashboardIntegrationsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/report': {
      id: '/_dashboard/report'
      path: '/report'
      fullPath: '/report'
      preLoaderRoute: typeof DashboardReportImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/settings': {
      id: '/_dashboard/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof DashboardSettingsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/': {
      id: '/_dashboard/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof DashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/graylog/alerts': {
      id: '/_dashboard/graylog/alerts'
      path: '/alerts'
      fullPath: '/graylog/alerts'
      preLoaderRoute: typeof DashboardGraylogAlertsImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/integrations/$id': {
      id: '/_dashboard/integrations/$id'
      path: '/$id'
      fullPath: '/integrations/$id'
      preLoaderRoute: typeof DashboardIntegrationsIdImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/playbooks/$id': {
      id: '/_dashboard/playbooks/$id'
      path: '/playbooks/$id'
      fullPath: '/playbooks/$id'
      preLoaderRoute: typeof DashboardPlaybooksIdImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/settings/notifications': {
      id: '/_dashboard/settings/notifications'
      path: '/notifications'
      fullPath: '/settings/notifications'
      preLoaderRoute: typeof DashboardSettingsNotificationsImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/settings/profile': {
      id: '/_dashboard/settings/profile'
      path: '/profile'
      fullPath: '/settings/profile'
      preLoaderRoute: typeof DashboardSettingsProfileImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/settings/term-dates': {
      id: '/_dashboard/settings/term-dates'
      path: '/term-dates'
      fullPath: '/settings/term-dates'
      preLoaderRoute: typeof DashboardSettingsTermDatesImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/actions/': {
      id: '/_dashboard/actions/'
      path: '/actions'
      fullPath: '/actions'
      preLoaderRoute: typeof DashboardActionsIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/activity/': {
      id: '/_dashboard/activity/'
      path: '/activity'
      fullPath: '/activity'
      preLoaderRoute: typeof DashboardActivityIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/graylog/': {
      id: '/_dashboard/graylog/'
      path: '/'
      fullPath: '/graylog/'
      preLoaderRoute: typeof DashboardGraylogIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/integrations/': {
      id: '/_dashboard/integrations/'
      path: '/'
      fullPath: '/integrations/'
      preLoaderRoute: typeof DashboardIntegrationsIndexImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/playbooks/': {
      id: '/_dashboard/playbooks/'
      path: '/playbooks'
      fullPath: '/playbooks'
      preLoaderRoute: typeof DashboardPlaybooksIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/settings/': {
      id: '/_dashboard/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof DashboardSettingsIndexImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/unauthorized/': {
      id: '/_dashboard/unauthorized/'
      path: '/unauthorized'
      fullPath: '/unauthorized'
      preLoaderRoute: typeof DashboardUnauthorizedIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/graylog/automation-settings/$ruleId': {
      id: '/_dashboard/graylog/automation-settings/$ruleId'
      path: '/automation-settings/$ruleId'
      fullPath: '/graylog/automation-settings/$ruleId'
      preLoaderRoute: typeof DashboardGraylogAutomationSettingsRuleIdImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/integrations/new/$id': {
      id: '/_dashboard/integrations/new/$id'
      path: '/new/$id'
      fullPath: '/integrations/new/$id'
      preLoaderRoute: typeof DashboardIntegrationsNewIdImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/graylog/automation-settings/': {
      id: '/_dashboard/graylog/automation-settings/'
      path: '/automation-settings'
      fullPath: '/graylog/automation-settings'
      preLoaderRoute: typeof DashboardGraylogAutomationSettingsIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/graylog/event-definitions/': {
      id: '/_dashboard/graylog/event-definitions/'
      path: '/event-definitions'
      fullPath: '/graylog/event-definitions'
      preLoaderRoute: typeof DashboardGraylogEventDefinitionsIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
    '/_dashboard/integrations/new/': {
      id: '/_dashboard/integrations/new/'
      path: '/new'
      fullPath: '/integrations/new'
      preLoaderRoute: typeof DashboardIntegrationsNewIndexImport
      parentRoute: typeof DashboardIntegrationsImport
    }
    '/_dashboard/settings/contact-directory/': {
      id: '/_dashboard/settings/contact-directory/'
      path: '/contact-directory'
      fullPath: '/settings/contact-directory'
      preLoaderRoute: typeof DashboardSettingsContactDirectoryIndexImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/settings/tenant-settings/': {
      id: '/_dashboard/settings/tenant-settings/'
      path: '/tenant-settings'
      fullPath: '/settings/tenant-settings'
      preLoaderRoute: typeof DashboardSettingsTenantSettingsIndexImport
      parentRoute: typeof DashboardSettingsImport
    }
    '/_dashboard/graylog/automation-settings/new/': {
      id: '/_dashboard/graylog/automation-settings/new/'
      path: '/automation-settings/new'
      fullPath: '/graylog/automation-settings/new'
      preLoaderRoute: typeof DashboardGraylogAutomationSettingsNewIndexImport
      parentRoute: typeof DashboardGraylogImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthLogoutRoute: typeof AuthLogoutRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthLogoutRoute: AuthLogoutRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface DashboardGraylogRouteChildren {
  DashboardGraylogAlertsRoute: typeof DashboardGraylogAlertsRoute
  DashboardGraylogIndexRoute: typeof DashboardGraylogIndexRoute
  DashboardGraylogAutomationSettingsRuleIdRoute: typeof DashboardGraylogAutomationSettingsRuleIdRoute
  DashboardGraylogAutomationSettingsIndexRoute: typeof DashboardGraylogAutomationSettingsIndexRoute
  DashboardGraylogEventDefinitionsIndexRoute: typeof DashboardGraylogEventDefinitionsIndexRoute
  DashboardGraylogAutomationSettingsNewIndexRoute: typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

const DashboardGraylogRouteChildren: DashboardGraylogRouteChildren = {
  DashboardGraylogAlertsRoute: DashboardGraylogAlertsRoute,
  DashboardGraylogIndexRoute: DashboardGraylogIndexRoute,
  DashboardGraylogAutomationSettingsRuleIdRoute:
    DashboardGraylogAutomationSettingsRuleIdRoute,
  DashboardGraylogAutomationSettingsIndexRoute:
    DashboardGraylogAutomationSettingsIndexRoute,
  DashboardGraylogEventDefinitionsIndexRoute:
    DashboardGraylogEventDefinitionsIndexRoute,
  DashboardGraylogAutomationSettingsNewIndexRoute:
    DashboardGraylogAutomationSettingsNewIndexRoute,
}

const DashboardGraylogRouteWithChildren =
  DashboardGraylogRoute._addFileChildren(DashboardGraylogRouteChildren)

interface DashboardIntegrationsRouteChildren {
  DashboardIntegrationsIdRoute: typeof DashboardIntegrationsIdRoute
  DashboardIntegrationsIndexRoute: typeof DashboardIntegrationsIndexRoute
  DashboardIntegrationsNewIdRoute: typeof DashboardIntegrationsNewIdRoute
  DashboardIntegrationsNewIndexRoute: typeof DashboardIntegrationsNewIndexRoute
}

const DashboardIntegrationsRouteChildren: DashboardIntegrationsRouteChildren = {
  DashboardIntegrationsIdRoute: DashboardIntegrationsIdRoute,
  DashboardIntegrationsIndexRoute: DashboardIntegrationsIndexRoute,
  DashboardIntegrationsNewIdRoute: DashboardIntegrationsNewIdRoute,
  DashboardIntegrationsNewIndexRoute: DashboardIntegrationsNewIndexRoute,
}

const DashboardIntegrationsRouteWithChildren =
  DashboardIntegrationsRoute._addFileChildren(
    DashboardIntegrationsRouteChildren,
  )

interface DashboardSettingsRouteChildren {
  DashboardSettingsNotificationsRoute: typeof DashboardSettingsNotificationsRoute
  DashboardSettingsProfileRoute: typeof DashboardSettingsProfileRoute
  DashboardSettingsTermDatesRoute: typeof DashboardSettingsTermDatesRoute
  DashboardSettingsIndexRoute: typeof DashboardSettingsIndexRoute
  DashboardSettingsContactDirectoryIndexRoute: typeof DashboardSettingsContactDirectoryIndexRoute
  DashboardSettingsTenantSettingsIndexRoute: typeof DashboardSettingsTenantSettingsIndexRoute
}

const DashboardSettingsRouteChildren: DashboardSettingsRouteChildren = {
  DashboardSettingsNotificationsRoute: DashboardSettingsNotificationsRoute,
  DashboardSettingsProfileRoute: DashboardSettingsProfileRoute,
  DashboardSettingsTermDatesRoute: DashboardSettingsTermDatesRoute,
  DashboardSettingsIndexRoute: DashboardSettingsIndexRoute,
  DashboardSettingsContactDirectoryIndexRoute:
    DashboardSettingsContactDirectoryIndexRoute,
  DashboardSettingsTenantSettingsIndexRoute:
    DashboardSettingsTenantSettingsIndexRoute,
}

const DashboardSettingsRouteWithChildren =
  DashboardSettingsRoute._addFileChildren(DashboardSettingsRouteChildren)

interface DashboardRouteChildren {
  DashboardGraylogRoute: typeof DashboardGraylogRouteWithChildren
  DashboardIntegrationsRoute: typeof DashboardIntegrationsRouteWithChildren
  DashboardReportRoute: typeof DashboardReportRoute
  DashboardSettingsRoute: typeof DashboardSettingsRouteWithChildren
  DashboardIndexRoute: typeof DashboardIndexRoute
  DashboardPlaybooksIdRoute: typeof DashboardPlaybooksIdRoute
  DashboardActionsIndexRoute: typeof DashboardActionsIndexRoute
  DashboardActivityIndexRoute: typeof DashboardActivityIndexRoute
  DashboardPlaybooksIndexRoute: typeof DashboardPlaybooksIndexRoute
  DashboardUnauthorizedIndexRoute: typeof DashboardUnauthorizedIndexRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardGraylogRoute: DashboardGraylogRouteWithChildren,
  DashboardIntegrationsRoute: DashboardIntegrationsRouteWithChildren,
  DashboardReportRoute: DashboardReportRoute,
  DashboardSettingsRoute: DashboardSettingsRouteWithChildren,
  DashboardIndexRoute: DashboardIndexRoute,
  DashboardPlaybooksIdRoute: DashboardPlaybooksIdRoute,
  DashboardActionsIndexRoute: DashboardActionsIndexRoute,
  DashboardActivityIndexRoute: DashboardActivityIndexRoute,
  DashboardPlaybooksIndexRoute: DashboardPlaybooksIndexRoute,
  DashboardUnauthorizedIndexRoute: DashboardUnauthorizedIndexRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof DashboardRouteWithChildren
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/logout': typeof AuthLogoutRoute
  '/graylog': typeof DashboardGraylogRouteWithChildren
  '/integrations': typeof DashboardIntegrationsRouteWithChildren
  '/report': typeof DashboardReportRoute
  '/settings': typeof DashboardSettingsRouteWithChildren
  '/': typeof DashboardIndexRoute
  '/graylog/alerts': typeof DashboardGraylogAlertsRoute
  '/integrations/$id': typeof DashboardIntegrationsIdRoute
  '/playbooks/$id': typeof DashboardPlaybooksIdRoute
  '/settings/notifications': typeof DashboardSettingsNotificationsRoute
  '/settings/profile': typeof DashboardSettingsProfileRoute
  '/settings/term-dates': typeof DashboardSettingsTermDatesRoute
  '/actions': typeof DashboardActionsIndexRoute
  '/activity': typeof DashboardActivityIndexRoute
  '/graylog/': typeof DashboardGraylogIndexRoute
  '/integrations/': typeof DashboardIntegrationsIndexRoute
  '/playbooks': typeof DashboardPlaybooksIndexRoute
  '/settings/': typeof DashboardSettingsIndexRoute
  '/unauthorized': typeof DashboardUnauthorizedIndexRoute
  '/graylog/automation-settings/$ruleId': typeof DashboardGraylogAutomationSettingsRuleIdRoute
  '/integrations/new/$id': typeof DashboardIntegrationsNewIdRoute
  '/graylog/automation-settings': typeof DashboardGraylogAutomationSettingsIndexRoute
  '/graylog/event-definitions': typeof DashboardGraylogEventDefinitionsIndexRoute
  '/integrations/new': typeof DashboardIntegrationsNewIndexRoute
  '/settings/contact-directory': typeof DashboardSettingsContactDirectoryIndexRoute
  '/settings/tenant-settings': typeof DashboardSettingsTenantSettingsIndexRoute
  '/graylog/automation-settings/new': typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/logout': typeof AuthLogoutRoute
  '/report': typeof DashboardReportRoute
  '/': typeof DashboardIndexRoute
  '/graylog/alerts': typeof DashboardGraylogAlertsRoute
  '/integrations/$id': typeof DashboardIntegrationsIdRoute
  '/playbooks/$id': typeof DashboardPlaybooksIdRoute
  '/settings/notifications': typeof DashboardSettingsNotificationsRoute
  '/settings/profile': typeof DashboardSettingsProfileRoute
  '/settings/term-dates': typeof DashboardSettingsTermDatesRoute
  '/actions': typeof DashboardActionsIndexRoute
  '/activity': typeof DashboardActivityIndexRoute
  '/graylog': typeof DashboardGraylogIndexRoute
  '/integrations': typeof DashboardIntegrationsIndexRoute
  '/playbooks': typeof DashboardPlaybooksIndexRoute
  '/settings': typeof DashboardSettingsIndexRoute
  '/unauthorized': typeof DashboardUnauthorizedIndexRoute
  '/graylog/automation-settings/$ruleId': typeof DashboardGraylogAutomationSettingsRuleIdRoute
  '/integrations/new/$id': typeof DashboardIntegrationsNewIdRoute
  '/graylog/automation-settings': typeof DashboardGraylogAutomationSettingsIndexRoute
  '/graylog/event-definitions': typeof DashboardGraylogEventDefinitionsIndexRoute
  '/integrations/new': typeof DashboardIntegrationsNewIndexRoute
  '/settings/contact-directory': typeof DashboardSettingsContactDirectoryIndexRoute
  '/settings/tenant-settings': typeof DashboardSettingsTenantSettingsIndexRoute
  '/graylog/automation-settings/new': typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_dashboard': typeof DashboardRouteWithChildren
  '/_auth/forgot-password': typeof AuthForgotPasswordRoute
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/logout': typeof AuthLogoutRoute
  '/_dashboard/graylog': typeof DashboardGraylogRouteWithChildren
  '/_dashboard/integrations': typeof DashboardIntegrationsRouteWithChildren
  '/_dashboard/report': typeof DashboardReportRoute
  '/_dashboard/settings': typeof DashboardSettingsRouteWithChildren
  '/_dashboard/': typeof DashboardIndexRoute
  '/_dashboard/graylog/alerts': typeof DashboardGraylogAlertsRoute
  '/_dashboard/integrations/$id': typeof DashboardIntegrationsIdRoute
  '/_dashboard/playbooks/$id': typeof DashboardPlaybooksIdRoute
  '/_dashboard/settings/notifications': typeof DashboardSettingsNotificationsRoute
  '/_dashboard/settings/profile': typeof DashboardSettingsProfileRoute
  '/_dashboard/settings/term-dates': typeof DashboardSettingsTermDatesRoute
  '/_dashboard/actions/': typeof DashboardActionsIndexRoute
  '/_dashboard/activity/': typeof DashboardActivityIndexRoute
  '/_dashboard/graylog/': typeof DashboardGraylogIndexRoute
  '/_dashboard/integrations/': typeof DashboardIntegrationsIndexRoute
  '/_dashboard/playbooks/': typeof DashboardPlaybooksIndexRoute
  '/_dashboard/settings/': typeof DashboardSettingsIndexRoute
  '/_dashboard/unauthorized/': typeof DashboardUnauthorizedIndexRoute
  '/_dashboard/graylog/automation-settings/$ruleId': typeof DashboardGraylogAutomationSettingsRuleIdRoute
  '/_dashboard/integrations/new/$id': typeof DashboardIntegrationsNewIdRoute
  '/_dashboard/graylog/automation-settings/': typeof DashboardGraylogAutomationSettingsIndexRoute
  '/_dashboard/graylog/event-definitions/': typeof DashboardGraylogEventDefinitionsIndexRoute
  '/_dashboard/integrations/new/': typeof DashboardIntegrationsNewIndexRoute
  '/_dashboard/settings/contact-directory/': typeof DashboardSettingsContactDirectoryIndexRoute
  '/_dashboard/settings/tenant-settings/': typeof DashboardSettingsTenantSettingsIndexRoute
  '/_dashboard/graylog/automation-settings/new/': typeof DashboardGraylogAutomationSettingsNewIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/forgot-password'
    | '/login'
    | '/logout'
    | '/graylog'
    | '/integrations'
    | '/report'
    | '/settings'
    | '/'
    | '/graylog/alerts'
    | '/integrations/$id'
    | '/playbooks/$id'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/term-dates'
    | '/actions'
    | '/activity'
    | '/graylog/'
    | '/integrations/'
    | '/playbooks'
    | '/settings/'
    | '/unauthorized'
    | '/graylog/automation-settings/$ruleId'
    | '/integrations/new/$id'
    | '/graylog/automation-settings'
    | '/graylog/event-definitions'
    | '/integrations/new'
    | '/settings/contact-directory'
    | '/settings/tenant-settings'
    | '/graylog/automation-settings/new'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/forgot-password'
    | '/login'
    | '/logout'
    | '/report'
    | '/'
    | '/graylog/alerts'
    | '/integrations/$id'
    | '/playbooks/$id'
    | '/settings/notifications'
    | '/settings/profile'
    | '/settings/term-dates'
    | '/actions'
    | '/activity'
    | '/graylog'
    | '/integrations'
    | '/playbooks'
    | '/settings'
    | '/unauthorized'
    | '/graylog/automation-settings/$ruleId'
    | '/integrations/new/$id'
    | '/graylog/automation-settings'
    | '/graylog/event-definitions'
    | '/integrations/new'
    | '/settings/contact-directory'
    | '/settings/tenant-settings'
    | '/graylog/automation-settings/new'
  id:
    | '__root__'
    | '/_auth'
    | '/_dashboard'
    | '/_auth/forgot-password'
    | '/_auth/login'
    | '/_auth/logout'
    | '/_dashboard/graylog'
    | '/_dashboard/integrations'
    | '/_dashboard/report'
    | '/_dashboard/settings'
    | '/_dashboard/'
    | '/_dashboard/graylog/alerts'
    | '/_dashboard/integrations/$id'
    | '/_dashboard/playbooks/$id'
    | '/_dashboard/settings/notifications'
    | '/_dashboard/settings/profile'
    | '/_dashboard/settings/term-dates'
    | '/_dashboard/actions/'
    | '/_dashboard/activity/'
    | '/_dashboard/graylog/'
    | '/_dashboard/integrations/'
    | '/_dashboard/playbooks/'
    | '/_dashboard/settings/'
    | '/_dashboard/unauthorized/'
    | '/_dashboard/graylog/automation-settings/$ruleId'
    | '/_dashboard/integrations/new/$id'
    | '/_dashboard/graylog/automation-settings/'
    | '/_dashboard/graylog/event-definitions/'
    | '/_dashboard/integrations/new/'
    | '/_dashboard/settings/contact-directory/'
    | '/_dashboard/settings/tenant-settings/'
    | '/_dashboard/graylog/automation-settings/new/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  DashboardRoute: typeof DashboardRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  DashboardRoute: DashboardRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_dashboard"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/forgot-password",
        "/_auth/login",
        "/_auth/logout"
      ]
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/graylog",
        "/_dashboard/integrations",
        "/_dashboard/report",
        "/_dashboard/settings",
        "/_dashboard/",
        "/_dashboard/playbooks/$id",
        "/_dashboard/actions/",
        "/_dashboard/activity/",
        "/_dashboard/playbooks/",
        "/_dashboard/unauthorized/"
      ]
    },
    "/_auth/forgot-password": {
      "filePath": "_auth/forgot-password.tsx",
      "parent": "/_auth"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/logout": {
      "filePath": "_auth/logout.tsx",
      "parent": "/_auth"
    },
    "/_dashboard/graylog": {
      "filePath": "_dashboard/graylog.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/graylog/alerts",
        "/_dashboard/graylog/",
        "/_dashboard/graylog/automation-settings/$ruleId",
        "/_dashboard/graylog/automation-settings/",
        "/_dashboard/graylog/event-definitions/",
        "/_dashboard/graylog/automation-settings/new/"
      ]
    },
    "/_dashboard/integrations": {
      "filePath": "_dashboard/integrations.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/integrations/$id",
        "/_dashboard/integrations/",
        "/_dashboard/integrations/new/$id",
        "/_dashboard/integrations/new/"
      ]
    },
    "/_dashboard/report": {
      "filePath": "_dashboard/report.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/settings": {
      "filePath": "_dashboard/settings.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/settings/notifications",
        "/_dashboard/settings/profile",
        "/_dashboard/settings/term-dates",
        "/_dashboard/settings/",
        "/_dashboard/settings/contact-directory/",
        "/_dashboard/settings/tenant-settings/"
      ]
    },
    "/_dashboard/": {
      "filePath": "_dashboard/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/graylog/alerts": {
      "filePath": "_dashboard/graylog/alerts.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/integrations/$id": {
      "filePath": "_dashboard/integrations/$id.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/playbooks/$id": {
      "filePath": "_dashboard/playbooks.$id.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/settings/notifications": {
      "filePath": "_dashboard/settings/notifications.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/profile": {
      "filePath": "_dashboard/settings/profile.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/term-dates": {
      "filePath": "_dashboard/settings/term-dates.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/actions/": {
      "filePath": "_dashboard/actions.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/activity/": {
      "filePath": "_dashboard/activity.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/graylog/": {
      "filePath": "_dashboard/graylog/index.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/integrations/": {
      "filePath": "_dashboard/integrations/index.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/playbooks/": {
      "filePath": "_dashboard/playbooks.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/settings/": {
      "filePath": "_dashboard/settings/index.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/unauthorized/": {
      "filePath": "_dashboard/unauthorized.index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/graylog/automation-settings/$ruleId": {
      "filePath": "_dashboard/graylog/automation-settings.$ruleId.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/integrations/new/$id": {
      "filePath": "_dashboard/integrations/new.$id.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/graylog/automation-settings/": {
      "filePath": "_dashboard/graylog/automation-settings.index.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/graylog/event-definitions/": {
      "filePath": "_dashboard/graylog/event-definitions.index.tsx",
      "parent": "/_dashboard/graylog"
    },
    "/_dashboard/integrations/new/": {
      "filePath": "_dashboard/integrations/new.index.tsx",
      "parent": "/_dashboard/integrations"
    },
    "/_dashboard/settings/contact-directory/": {
      "filePath": "_dashboard/settings/contact-directory.index.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/tenant-settings/": {
      "filePath": "_dashboard/settings/tenant-settings.index.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/graylog/automation-settings/new/": {
      "filePath": "_dashboard/graylog/automation-settings.new.index.tsx",
      "parent": "/_dashboard/graylog"
    }
  }
}
ROUTE_MANIFEST_END */
