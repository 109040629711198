import { Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'

import { actionQueries } from '@/features/actions/queries'
import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import AutomationSettingsDetailsForm from '@/features/graylog/automation-settings/component/details/form'
import { graylogQueries } from '@/features/graylog/queries'
import { playbookQueries } from '@/features/playbooks/queries'
import { getCurrentTenant } from '@/features/user/store'

import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

export const Route = createFileRoute(
	'/_dashboard/graylog/automation-settings/$ruleId',
)({
	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ context: { queryClient }, params }) => {
		const { ruleId } = params

		// If the ruleId is 'new', we don't want to fetch any data
		if (!ruleId || ruleId === 'new') return

		const tenant_id = getCurrentTenant() as string

		queryClient.prefetchQuery(actionQueries.list(tenant_id))
		queryClient.prefetchQuery(playbookQueries.list(tenant_id))

		// Fetch event details
		return await queryClient.ensureQueryData(
			graylogQueries.automationDetail(tenant_id, ruleId),
		)
	},

	component: AutomationSettingsEvent,
	pendingComponent: AutomationSettingsEventFallback,
})

function AutomationSettingsEvent() {
	const { ruleId } = Route.useParams()

	return (
		<div className="flex w-full flex-col items-start justify-start gap-2">
			<ErrorBoundary>
				<Suspense fallback={<TableSkeleton rows={10} />}>
					<AutomationSettingsDetailsForm ruleId={ruleId} />
				</Suspense>
			</ErrorBoundary>
		</div>
	)
}

function AutomationSettingsEventFallback() {
	return (
		<div className="flex w-full flex-row items-center justify-between">
			<Skeleton className="h-12 w-1/2" />
			<Skeleton className="h-12 w-1/2" />
		</div>
	)
}
