import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { ErrorBoundary } from '@sentry/react'
import { z } from 'zod'

import { graylogQueries } from '@/features/graylog/queries'
import { getCurrentTenant } from '@/features/user/store'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

const EventDefinitionTable = lazy(
	() => import('@/features/graylog/event-definitions/components/table'),
)

const searchSchema = z.object({
	action: z.string().optional(),
})

export const Route = createFileRoute('/_dashboard/graylog/event-definitions/')({
	validateSearch: zodValidator(searchSchema),

	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string

		await queryClient.ensureQueryData(graylogQueries.events(tenant))
	},

	component: EnabledAlertsRoute,
	pendingComponent: EnabledAlertsRouteSkeleton,
})

function EnabledAlertsRoute() {
	return (
		<>
			<title>Event Definitions | Graylog | BitLyft Air®</title>
			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<TableSkeleton />}>
					<EventDefinitionTable />
				</Suspense>
			</ErrorBoundary>
		</>
	)
}

function EnabledAlertsRouteSkeleton() {
	return (
		<div>
			{Array.from({ length: 24 }).map((_, i) => (
				<Skeleton key={i} className="my-2 h-12 w-full" />
			))}
		</div>
	)
}
