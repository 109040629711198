import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { staleFor } from '@/lib/react-query'
import { defaultQueryRetry, time } from '@/lib/utils'

import { fetchMe } from './api'

export const userKeys = {
	all: ['user'] as const,
	me: () => [...userKeys.all, 'me'],
}

export const userQueries = {
	me: () =>
		queryOptions({
			queryKey: userKeys.me(),
			queryFn: fetchMe,
			retry: defaultQueryRetry,
			// Data will live in memory for 15 minutes after being fetched
			gcTime: time(15, 'm'),
			// Data will be considered stale after 5 minutes
			staleTime: (query) => staleFor(query.state, 5, 'm'),
		}),
}

export const useMe = () => {
	return useSuspenseQuery(userQueries.me())
}
