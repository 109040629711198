import { lazy, Suspense } from 'react'

import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { ErrorBoundary } from '@sentry/react'
import { z } from 'zod'

import { actionQueries } from '@/features/actions/queries'
import { activityQueries } from '@/features/activity/queries'
import { useClearCache } from '@/features/app/hooks/useClearCache'
import {
	useAppActions,
	useAppLoading,
	useAppSidebar,
} from '@/features/app/store'
import { hasRequiredRole } from '@/features/auth/helpers'
import { isUserAuthenticated } from '@/features/auth/queries'
import { resetForgotPassword } from '@/features/auth/store/forgot-password-store'
import { resetLogin } from '@/features/auth/store/login-store'
import { Roles } from '@/features/auth/types'
import { useMe, userQueries } from '@/features/user/queries'
import {
	getCurrentRole,
	getCurrentTenant,
	UserStoreProvider,
} from '@/features/user/store'
import { Wizard } from '@/features/wizard/components/wizard'
import { wizazrdQueries } from '@/features/wizard/queries'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Header } from '@/components/environment/header'
import { NotFoundContent } from '@/components/environment/not-found-content'
import { ScreenLoader } from '@/components/environment/screen-loader'
import { AppSidebar } from '@/components/environment/sidebar'
import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar'
import { Toaster as Sonner } from '@/components/ui/sonner'

// Lazy load non-critical components
const Panel = lazy(() => import('@/features/panel/components/panel'))
const NavigationLoader = lazy(
	() => import('@/components/environment/navigation-loader'),
)
const ConfirmationDialog = lazy(
	() => import('@/features/confirmation-dialog/components/confirmation-dialog'),
)

const dashboardSearchSchema = z.object({
	forceCache: z.boolean().optional(),
})

/**
 * Dashboard layout that wraps the entire dashboard.
 */
export const Route = createFileRoute('/_dashboard')({
	validateSearch: zodValidator(dashboardSearchSchema),

	/**
	 * We check the user auth session before loading the dashboard.
	 * @see https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes
	 */

	beforeLoad: async ({ context: { queryClient } }) => {
		const isAuthenticated = await isUserAuthenticated(queryClient)

		// If user is not authenticated, redirect to the logout page to make sure the user session is cleared
		if (!isAuthenticated) {
			throw redirect({
				to: '/logout',
				search: { redirect: encodeURIComponent(window.location.href) },
			})
		}

		// Ensure the user data is loaded
		await queryClient.ensureQueryData(userQueries.me())
	},

	/**
	 * We call the ensureQueryData function to ensure that the user data and
	 * avoid a loading state for the dashboard
	 */
	loader: async ({ context: { queryClient } }) => {
		// Reset the login and forgot password store states before loading the dashboard
		// This is necessary to avoid any potential issues with the user session
		resetLogin()
		resetForgotPassword()

		const tenant = getCurrentTenant() as string
		const role = getCurrentRole() as Roles

		// If user has the Analyst role or above
		if (hasRequiredRole(role, Roles.enum.Analyst)) {
			// Prefetch action list and activities
			queryClient.prefetchQuery(actionQueries.list(tenant))
			queryClient.prefetchInfiniteQuery(activityQueries.list(tenant))
		}

		// If user has the SystemManager role
		if (hasRequiredRole(role, Roles.enum.SystemManager)) {
			// Prefetch onboarding in progress list
			queryClient.prefetchQuery(wizazrdQueries.onboardingInProgress(tenant))
		}
	},

	/**
	 * Finally, we provide the component to render when the route is ready
	 */
	component: DashboardLayout,
	errorComponent: DashboardError,
	notFoundComponent: NotFoundContent,
})

function DashboardLayout() {
	// Clear the query cache when the forceCache search param is true
	useClearCache()

	// Get the user data
	const {
		data: { user, tenant_roles },
	} = useMe()

	const isSidebarOpen = useAppSidebar()
	const isLoading = useAppLoading()

	const { toggleSidebar } = useAppActions()

	return (
		<SidebarProvider open={isSidebarOpen} onOpenChange={toggleSidebar}>
			<UserStoreProvider
				// Provide the user data to the user store on mount
				initialState={{
					currentRole: tenant_roles[user?.tenant ?? ''],
					currentTenant: user?.tenant ?? null,
				}}
			>
				{isLoading && <ScreenLoader />}

				<AppSidebar />

				<SidebarInset>
					<Header />
					<Outlet />

					<Suspense fallback={null}>
						<Panel />
					</Suspense>

					<ErrorBoundary fallback={() => <></>}>
						<Suspense fallback={null}>
							<Sonner />
							<NavigationLoader />
							<Wizard />
							<ConfirmationDialog />
						</Suspense>
					</ErrorBoundary>
				</SidebarInset>
			</UserStoreProvider>
		</SidebarProvider>
	)
}

/**
 * Error component for the dashboard
 */
function DashboardError() {
	const { reset } = useErrorBoundary()

	// Get the user data
	const {
		data: { user, tenant_roles },
	} = useMe()

	const isSidebarOpen = useAppSidebar()
	const isLoading = useAppLoading()
	const { toggleSidebar } = useAppActions()

	return (
		<SidebarProvider open={isSidebarOpen} onOpenChange={toggleSidebar}>
			<UserStoreProvider
				// Provide the user data to the user store on mount
				initialState={{
					currentRole: tenant_roles[user?.tenant ?? ''],
					currentTenant: user?.tenant ?? null,
				}}
			>
				{isLoading && <ScreenLoader />}

				<AppSidebar />

				<SidebarInset>
					<Header />

					<ErrorBoundaryComponent resetError={reset} />

					<Suspense fallback={null}>
						<Panel />
					</Suspense>

					<Sonner />
					<NavigationLoader />
					<Wizard />
					<ConfirmationDialog />
				</SidebarInset>
			</UserStoreProvider>
		</SidebarProvider>
	)
}
