import { useMemo } from 'react'

import type { Roles } from '../auth/types'
import type { Tenant, UserWithAvatar } from './types/api'

/**
 * Get the user initials
 * @param {UserWithAvatar | null} user - The user
 * @returns {string} The user initials
 */
export const getUserInitials = (user: UserWithAvatar | null) => {
	if (!user?.uname) return ''

	const email = user?.uname || ''
	const name = email.split('@')[0]
	const last = name.split('.')[1]
	const initials = last ? name[0] + last[0] : name[0] + name[1]
	return initials.toUpperCase()
}

/**
 * Get the tenant name by ID
 * @param {string | undefined} tenantID - The tenant ID
 * @returns {string | undefined} The tenant name
 */
export const getTenantNameById = (
	tenantID: string | undefined,
	allowed_tenants: Tenant[],
) => {
	const tenant = allowed_tenants?.find((tenant) => tenant.id === tenantID)
	return tenant?.fullname
}

/**
 * Get the list of allowed tenants where the user has roles on
 */
export const useTenantListWithRoles = (
	allowed_tenants: Tenant[],
	tenant_roles: Record<string, Roles>,
) => {
	// Memoize the result of this calculation
	return useMemo(() => {
		return allowed_tenants?.filter((tenant) =>
			Object.keys(tenant_roles).includes(tenant.id),
		)
	}, [allowed_tenants, tenant_roles])
}
