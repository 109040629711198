import { Link } from '@tanstack/react-router'

import { Button } from '../ui/button'

import { LogoText } from './logo-text'

export const NotFoundContent = () => {
	return (
		<main className="flex h-screen w-full flex-col items-center justify-center">
			<LogoText className="my-8 w-48" />
			<p className="text-lg font-medium">
				We couldn't find the page you were looking for.
			</p>
			<Button variant="outline" asChild className="my-4">
				<Link to="/graylog">Go home</Link>
			</Button>
		</main>
	)
}
