import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { z } from 'zod'

import { sentry } from '@/lib/sentry'

import { PanelForm } from '@/features/actions/components/form'
import SettingsForm from '@/features/actions/components/settings'
import { actionQueries } from '@/features/actions/queries'
import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { usePanelActions } from '@/features/panel/store'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'
import { useUpdateSearch } from '@/hooks/useUpdateSearch'

import QueryBoundary from '@/components/common/query-boundary'
import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { Action } from '@/features/actions/types'
import type { XiorError } from 'xior'

const ModulesSearch = lazy(() => import('@/features/actions/components/search'))
const ModulesTable = lazy(() => import('@/features/actions/components/table'))
const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

const searchSchema = z.object({
	q: z.string().optional(),
	action: z.string().optional(),
	settings: z.string().optional(),
	filters: z
		.object({
			vendor: z.string().optional(),
		})
		.optional(),
})

export type ActionsSearch = z.infer<typeof searchSchema>

export const Route = createFileRoute('/_dashboard/actions/')({
	validateSearch: zodValidator(searchSchema),

	loaderDeps: ({ search }) => search,

	// Only Analysts can access this route
	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	// Make sure the actions are fetched
	loader: async ({ context: { queryClient }, deps: search }) => {
		const tenant = getCurrentTenant() as string
		queryClient.ensureQueryData(actionQueries.list(tenant, search))
	},

	component: RemediationActionsRoute,
	pendingComponent: RemediationActionsRouteSkeleton,
	errorComponent: RemediationActionsError,
})

function RemediationActionsRoute() {
	const { update } = useUpdateSearch()
	const { open, createPanel, close } = usePanelActions()

	const [query, setQuery] = useState<string>('')

	/**
	 * PANEL CONTROLS
	 */

	// Handles the selection of a module and updates the URL
	const handleSelectModule = (module: Action, type: 'run' | 'settings') => {
		// Set the panel
		if (type === 'run') {
			createPanel({
				title: `Run: ${module.friendly_name}`,
				description: module.description,
				content: <PanelForm module={module} handleClose={close} />,
				onClose: handleClose,
			})
			update({ action: module.action_id })
		}

		if (type === 'settings') {
			createPanel({
				title: `Settings: ${module.friendly_name}`,
				description: module.description,
				content: <SettingsForm handleClose={close} />,
				onClose: handleClose,
			})
			update({ settings: module.action_id })
		}

		// Open the panel
		open()
	}

	// Handles the closing of the panel and updates the URL
	const handleClose = async () => {
		update({ action: undefined })
	}

	return (
		<Section>
			<title>Actions | BitLyft Air®</title>

			<SectionTitle>Actions</SectionTitle>

			<div className="flex flex-col gap-4">
				<Suspense fallback={<Skeleton className="my-2 h-12 w-full" />}>
					<ModulesSearch
						query={query}
						onQueryChange={setQuery}
						onModuleSelect={handleSelectModule}
					/>
				</Suspense>

				{/* We wrap the table in a suspense for the loading state */}

				<QueryBoundary
					fallback={({ resetError }) => (
						<ErrorBoundaryComponent resetError={resetError} />
					)}
				>
					<Suspense fallback={<TableSkeleton />}>
						<ModulesTable query={query} onModuleSelect={handleSelectModule} />
					</Suspense>
				</QueryBoundary>
			</div>
		</Section>
	)
}

/**
 * Loading state for Remediation Actions
 */
function RemediationActionsRouteSkeleton() {
	return (
		<Section>
			<title>Actions | BitLyft Air®</title>
			<SectionTitle>Actions</SectionTitle>

			<TableSkeleton />
		</Section>
	)
}

/**
 * Error component for Remediation Actions
 */
function RemediationActionsError({ error }: { error: unknown }) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	sentry.captureException(err)

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<Section>
			<title>Actions | BitLyft Air®</title>
			<SectionTitle>Actions</SectionTitle>

			<ErrorBoundaryComponent
				errorCode={err.response?.status?.toString()}
				title="Error fetching actions"
				message="An error occurred while fetching actions. If this persists, please contact support."
				resetError={reset}
			/>
		</Section>
	)
}
