import { useEffect, useState } from 'react'

import { ChevronDown } from 'lucide-react'

import { delay } from '@/lib/utils'

import { useMe } from '@/features/user/queries'
import { useCurrentTenant, useUserActions } from '@/features/user/store'
import { useIsDesktop } from '@/hooks/useIsDesktop'

import { ScreenLoader } from '@/components/environment/screen-loader'
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command'
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerTitle,
	DrawerTrigger,
} from '@/components/ui/drawer'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'

import type { Tenant } from '@/features/user/types/api'

export const TenantSettingsSelect = () => {
	const isDesktop = useIsDesktop()
	const currentTenantID = useCurrentTenant()
	const {
		data: { allowed_tenants },
	} = useMe()

	const [open, setOpen] = useState(false)
	const [selectedStatus, setSelectedTenant] = useState<Tenant | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		if (currentTenantID) {
			const currentTenant = allowed_tenants?.find(
				(tenant) => tenant.id === currentTenantID,
			)
			setSelectedTenant(currentTenant || null)
		}
	}, [currentTenantID])

	const handleSelect = async (tenant: Tenant | null) => {
		setSelectedTenant(tenant)
		setIsLoading(true)
		await delay(1000)
		setIsLoading(false)
	}

	if (isDesktop) {
		return (
			<>
				{isLoading && <ScreenLoader />}
				<Popover open={open} onOpenChange={setOpen}>
					<PopoverTrigger>
						<p className="flex flex-row items-center text-4xl font-bold">
							{selectedStatus ? (
								<>{selectedStatus.fullname}</>
							) : (
								<>Select Tenant</>
							)}
							<ChevronDown className="ml-2 h-4 w-4" />
						</p>
					</PopoverTrigger>
					<PopoverContent className="w-[200px] p-0" align="start">
						<TenantList setOpen={setOpen} setSelectedTenant={handleSelect} />
					</PopoverContent>
				</Popover>
			</>
		)
	}

	return (
		<>
			{isLoading && <ScreenLoader />}
			<Drawer open={open} onOpenChange={setOpen}>
				<DrawerTrigger asChild>
					<p className="flex flex-row items-center text-4xl font-bold">
						{selectedStatus ? (
							<>{selectedStatus.fullname}</>
						) : (
							<>Select Tenant</>
						)}
						<ChevronDown className="ml-2 h-4 w-4" />
					</p>
				</DrawerTrigger>
				<DrawerContent id="select-tenant-drawer" className="p-4">
					<DrawerTitle>Tenants</DrawerTitle>
					<DrawerDescription>Select Tenant</DrawerDescription>
					<div className="mt-4 border-t">
						<TenantList setOpen={setOpen} setSelectedTenant={handleSelect} />
					</div>
				</DrawerContent>
			</Drawer>
		</>
	)
}

type TenantListProps = {
	setOpen: (open: boolean) => void
	setSelectedTenant: (tenant: Tenant | null) => void
}

const TenantList = ({ setOpen, setSelectedTenant }: TenantListProps) => {
	const {
		data: { allowed_tenants },
	} = useMe()
	const { setCurrentTenant } = useUserActions()!

	return (
		<Command>
			<CommandInput placeholder="Select Tenant" />
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				<CommandGroup>
					{allowed_tenants?.map((tenant) => (
						<CommandItem
							key={tenant.id}
							value={tenant.fullname}
							onSelect={(value: string) => {
								const findTenant = allowed_tenants.find(
									(ten) => ten.fullname === value,
								)
								setSelectedTenant(findTenant || null)
								setCurrentTenant(findTenant?.id || undefined)
								setOpen(false)
							}}
						>
							{tenant.fullname}
						</CommandItem>
					))}
				</CommandGroup>
			</CommandList>
		</Command>
	)
}
