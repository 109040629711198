import { createWithEqualityFn } from 'zustand/traditional'

import type { OnboardingInProgress } from './types'

// Make key a const so we can import it in other files
export const APP_STORE_KEY = '@bitlyft/wizard'

type WizardState = {
	isOnboarding: string | null
	inProgress: OnboardingInProgress
	onboardingMetadata: Record<string, any>
}

type WizardActions = {
	setOnboardingInProgress: (onboardingInProgress: OnboardingInProgress) => void
	setIsOnboarding: (isOnboarding: string | null) => void
	startWizard: (onboarding: string) => void
	setOnboardingMetadata: (metadata: Record<string, any>) => void
	// Reset
	reset: () => void
}

type WizardStore = WizardState & {
	actions: WizardActions
}

const initialState: WizardState = {
	isOnboarding: null,
	inProgress: {
		onboardings: [],
	},
	onboardingMetadata: {},
}

const wizardStore = createWithEqualityFn<WizardStore>()((set) => ({
	// Set initial state
	...initialState,

	actions: {
		/**
		 * We pass two more arguments to set so we can have a better dev experience
		 * and inspect with Redux DevTools
		 * @see: https://github.com/pmndrs/zustand?tab=readme-ov-file#redux-devtools
		 */

		setOnboardingInProgress: (onboardingInProgress: OnboardingInProgress) =>
			set({ inProgress: onboardingInProgress }),

		setIsOnboarding: (isOnboarding: string | null) => set({ isOnboarding }),

		startWizard: (onboarding: string) => set({ isOnboarding: onboarding }),

		setOnboardingMetadata: (metadata: Record<string, any>) =>
			set({ onboardingMetadata: metadata }),

		// Reset state to initial state
		reset: () => set(initialState),
	},
}))

/**
 * Get the array of in progress onboardings
 */
export const useWizardHasProgress = () =>
	wizardStore((state) => state.inProgress)

/**
 * Get the current onboarding ID or null if no onboarding is in progress
 */
export const useWizardIsOpen = () => wizardStore((state) => state.isOnboarding)

/**
 * Get the onboarding metadata
 */
export const useWizardOnboardingMetadata = () =>
	wizardStore((state) => state.onboardingMetadata)

/**
 * Get the actions for the wizard
 */
export const useWizardActions = () => wizardStore((state) => state.actions)
