import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'

import { ErrorBoundary } from '@sentry/react'
import { z } from 'zod'

import { useReport } from '@/features/report/hooks/useReport'
import { reportQueries } from '@/features/report/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import UnauthorizedContent from '@/components/environment/unauthorized-content'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const ReportContent = lazy(
	() => import('@/features/report/components/report-content'),
)

const searchSchema = z.object({
	tenant: z.string().optional(),
	start_time: z.string().optional(),
	end_time: z.string().optional(),
})

export const Route = createFileRoute('/_dashboard/report')({
	validateSearch: zodSearchValidator(searchSchema),

	loaderDeps: ({ search: { start_time, end_time } }) => {
		return { start_time, end_time }
	},

	loader: async ({
		deps: { start_time, end_time },
		context: { queryClient },
	}) => {
		const tenant = getCurrentTenant() as string

		await queryClient.prefetchQuery(
			reportQueries.report(tenant, start_time, end_time),
		)
	},

	component: ReportRoute,
	pendingComponent: ReportPending,
	errorComponent: ReportError,
})

function ReportRoute() {
	const { tenantName } = useReport()

	return (
		<Section>
			<SectionTitle>Report - {tenantName}</SectionTitle>

			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<ReportSkeleton />}>
					<ReportContent />
				</Suspense>
			</ErrorBoundary>
		</Section>
	)
}

function ReportSkeleton() {
	return (
		<div className="flex flex-col items-start justify-start gap-4">
			<Skeleton className="h-[170px] w-full" />
			<Skeleton className="h-[170px] w-full" />
			<Skeleton className="h-[170px] w-full" />
		</div>
	)
}

function ReportPending() {
	return (
		<Section>
			<SectionTitle>Report</SectionTitle>

			<ReportSkeleton />
		</Section>
	)
}

function ReportError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<Section>
			<SectionTitle>Report</SectionTitle>

			<ErrorBoundaryComponent resetError={reset} />
		</Section>
	)
}
