/**
 * Assert a value is a string
 * @param value - The value to assert
 */
export function assertString(value: unknown): asserts value is string {
	if (typeof value !== 'string') throw new Error('Not a string')
}

/**
 * Assert a value is a number
 * @param value - The value to assert
 */
export function assertNumber(value: unknown): asserts value is number {
	if (typeof value !== 'number') throw new Error('Not a number')
}

/**
 * Assert a value is a boolean
 * @param value - The value to assert
 */
export function assertBoolean(value: unknown): asserts value is boolean {
	if (typeof value !== 'boolean') throw new Error('Not a boolean')
}

/**
 * Assert a value is an object
 * @param value - The value to assert
 */
export function assertObject(value: unknown): asserts value is object {
	if (typeof value !== 'object') throw new Error('Not an object')
}

/**
 * Assert a value is an array
 * @param value - The value to assert
 */
export function assertArray(value: unknown): asserts value is unknown[] {
	if (!Array.isArray(value)) throw new Error('Not an array')
}
