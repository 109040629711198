import { api } from '@/lib/api'

import type { CheckURLReachability, OnboardingInProgress } from './types'

/**
 * Fetch the whitelist IP for the current tenant
 * @param tenant - The tenant ID
 */
export const fetchWhitelistIP = async (tenant: string) => {
	if (!tenant) {
		return { air_ip: '' }
	}

	const { data } = await api.get<{ air_ip: string }>(
		`api/tenants/${tenant}/onboarding/air_ip`,
	)

	return data
}

/**
 * Fetch onboarding in progress for the current tenant
 * @param tenant - The tenant ID
 */
export const fetchOnboardingInProgress = async (tenant: string) => {
	if (!tenant) {
		return { onboardings: [] }
	}

	const { data } = await api.get<OnboardingInProgress>(
		`api/tenants/${tenant}/onboarding/in_progress`,
	)

	return data
}

/**
 * Check if a URL is reachable
 * @param tenant - The tenant ID
 * @param url - The URL to check
 */
export const checkURLReachability = async (tenant: string, url: string) => {
	const { data } = await api.post<CheckURLReachability>(
		`/api/tenants/${tenant}/onboarding/reachability_check`,
		{
			url,
		},
	)

	return data
}

/**
 * Update an integration onboarding process
 * @param tenant - The tenant ID
 * @param integration_id - The integration ID
 * @param integration_data - The integration data
 */
export const updateOnboarding = async (
	tenant: string,
	integration_id: string,
	integration_data: Record<string, any>,
) => {
	const { data } = await api.post<string>(
		`/api/tenants/${tenant}/onboarding/in_progress/${integration_id}`,
		integration_data,
	)

	return data
}

/**
 * Delete a integration onboarding process
 * @param tenant - The tenant ID
 * @param integration_id - The integration ID
 */
export const deleteIntegrationOnboarding = async (
	tenant: string,
	integration_id: string,
) => {
	const { data } = await api.delete<string>(
		`/api/tenants/${tenant}/onboarding/in_progress/${integration_id}`,
	)

	return data
}
