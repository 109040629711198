import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '../ui/command'
import { DialogDescription, DialogTitle } from '../ui/dialog'

import type { Tenant } from '@/features/user/types/api'

type SidebarTenantSearchProps = {
	isSearching: boolean
	setIsSearching: (isSearching: boolean) => void
	tenants: Tenant[]
	selectedTenant: Tenant | null
	handleSelect: (tenant: Tenant) => void
}

export const SidebarTenantSearch = ({
	isSearching,
	setIsSearching,
	tenants,
	selectedTenant,
	handleSelect,
}: SidebarTenantSearchProps) => {
	return (
		<CommandDialog open={isSearching} onOpenChange={setIsSearching}>
			<DialogDescription hidden>
				Search for a tenant to switch to
			</DialogDescription>
			<DialogTitle hidden>Search for a tenant to switch to</DialogTitle>
			<CommandInput placeholder="Type a command or search..." />
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				<CommandGroup heading="Tenants">
					{tenants.map((team) => (
						<CommandItem
							onSelect={() => {
								handleSelect(team)
								setIsSearching(false)
							}}
							key={team.fullname}
							className="cursor-pointer gap-2 p-2"
							disabled={team.id === selectedTenant?.id}
						>
							<span>{team.fullname}</span>
						</CommandItem>
					))}
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	)
}
