import { api } from '@/lib/api'

import type { ConfirmationHeaders } from '../confirmation-dialog/types'
import type { User, UserRequest } from './types/api'

/**
 * Fetch the current user's data
 */
export const fetchMe = async () => {
	// We fetch the user data
	const { data } = await api.get<UserRequest>('/me')

	return data
}

/**
 * Update the current user's data
 * @param user_data - Partial user data
 */
export const updateMe = async (user_data: Partial<User>) => {
	const { data } = await api.patch<UserRequest>(`/me`, user_data)

	return data
}

/**
 * Delete current user's data
 * @param headers - Confirmation headers
 */
export const deleteMe = async (headers?: ConfirmationHeaders) => {
	const { data } = await api.delete<string>(`/me`, {
		data: {},
		headers: headers
			? {
					'X-AIR-confirm': headers['X-AIR-confirm'],
					...(headers['X-AIR-confirm-passphrase'] && {
						'X-AIR-confirm-passphrase': headers['X-AIR-confirm-passphrase'],
					}),
				}
			: undefined,
	})

	return data
}
