import { Moon, Sun } from 'lucide-react'

import { useAppActions, useAppTheme } from '@/features/app/store'

import { DropdownMenuGroup, DropdownMenuItem } from '../ui/dropdown-menu'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '../ui/tooltip'

type ThemeToggleProps = {
	isMenu?: boolean
}

export const ThemeToggle = ({ isMenu = false }: ThemeToggleProps) => {
	const theme = useAppTheme()
	const { setTheme } = useAppActions()

	const handleToggleTheme = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark')
	}

	if (isMenu) {
		return (
			<DropdownMenuGroup>
				<DropdownMenuItem
					onClick={handleToggleTheme}
					className="cursor-pointer"
				>
					{theme === 'dark' ? (
						<>
							<Sun className="mr-2 h-6 w-6" /> Light Mode
						</>
					) : (
						<>
							<Moon className="mr-2 h-6 w-6" /> Dark Mode
						</>
					)}
				</DropdownMenuItem>
			</DropdownMenuGroup>
		)
	}

	return (
		<TooltipProvider delayDuration={0}>
			<Tooltip>
				<TooltipTrigger
					onClick={handleToggleTheme}
					name="theme_toggle_button"
					className="text-muted-foreground"
				>
					{theme === 'dark' ? <Sun /> : <Moon />}
				</TooltipTrigger>
				<TooltipContent side="bottom">
					{theme === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}

export default ThemeToggle
