import { lazy, Suspense, useEffect } from 'react'

import { useWizardOnboardingInProgress } from '../queries'
import { useWizardActions } from '../store'

const WizardDialog = lazy(() => import('./dialog'))

export const Wizard = () => {
	// Get the onboardings in progress from API
	// This returns an array of onboardings
	const { data } = useWizardOnboardingInProgress()

	// Set the onboardings in progress to the store whenever the data changes
	const { setOnboardingInProgress } = useWizardActions()

	useEffect(() => {
		if (data) {
			setOnboardingInProgress(data)
		}
	}, [data?.onboardings.length])

	return (
		<Suspense fallback={null}>
			<WizardDialog />
		</Suspense>
	)
}
