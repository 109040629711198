import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { ErrorBoundary } from '@sentry/react'
import { z } from 'zod'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { usePanelActions } from '@/features/panel/store'
import { playbookQueries } from '@/features/playbooks/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'
import { useUpdateSearch } from '@/hooks/useUpdateSearch'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { PlaybookShort } from '@/features/playbooks/types'
import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const PlaybooksSearch = lazy(
	() => import('@/features/playbooks/components/search'),
)

const PlaybooksTable = lazy(
	() => import('@/features/playbooks/components/table'),
)

const RunForm = lazy(() => import('@/features/playbooks/components/forms/run'))

const SettingsForm = lazy(
	() => import('@/features/playbooks/components/forms/settings'),
)

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

const searchSchema = z.object({
	q: z.string().optional(),
	action: z.string().optional(),
	settings: z.string().optional(),
})

export const Route = createFileRoute('/_dashboard/playbooks/')({
	validateSearch: zodValidator(searchSchema),

	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string

		// Prefetch the playbook list
		queryClient.prefetchQuery(playbookQueries.list(tenant))
	},

	component: PlaybooksRoute,
	pendingComponent: PlaybooksRouteSkeleton,
	errorComponent: PlaybooksRouteError,
})

function PlaybooksRoute() {
	const [query, setQuery] = useState<string>('')

	const { update } = useUpdateSearch()
	const { createPanel, open, close } = usePanelActions()

	const handleSelectPlaybook = (
		playbook: PlaybookShort,
		mode: 'run' | 'settings',
	) => {
		// We need to encode the name to be used as a search param
		const urlName = encodeURIComponent(playbook.name)

		createPanel({
			title: `${mode === 'run' ? 'Run' : 'Settings'}: ${playbook.name}`,
			description: playbook.description ?? undefined,
			content:
				mode === 'run' ? (
					<RunForm handleClose={close} />
				) : (
					<SettingsForm handleClose={close} />
				),
			onClose: handleClose,
		})

		open()

		if (mode === 'run') {
			update({ action: urlName })
		} else {
			update({ settings: urlName })
		}
	}

	const handleClose = async () => {
		// When the panel is closed, we need to reset the search params
		update({
			action: undefined,
			settings: undefined,
		})
	}

	return (
		<Section>
			<title>Playbooks | BitLyft Air®</title>
			<SectionTitle>Playbooks</SectionTitle>

			<div className="mb-4">
				<ErrorBoundary
					fallback={
						<div className="mb-4 text-red-500">Error loading search</div>
					}
				>
					<Suspense fallback={<Skeleton className="h-12 w-full" />}>
						<PlaybooksSearch
							query={query}
							onQueryChange={setQuery}
							onPlaybookSelect={handleSelectPlaybook}
						/>
					</Suspense>
				</ErrorBoundary>
			</div>

			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<TableSkeleton rows={5} />}>
					<PlaybooksTable
						query={query}
						onPlaybookSelect={handleSelectPlaybook}
					/>
				</Suspense>
			</ErrorBoundary>
		</Section>
	)
}

/**
 * Loading state for Remediation Actions
 */
function PlaybooksRouteSkeleton() {
	return (
		<Section>
			<SectionTitle>Playbooks</SectionTitle>
			<TableSkeleton rows={5} />
		</Section>
	)
}

/**
 * Error component for Remediation Actions
 */
function PlaybooksRouteError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
