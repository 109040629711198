import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { z } from 'zod'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { usePanelActions } from '@/features/panel/store'
import {
	SettingsCard,
	SettingsCardTitle,
} from '@/features/settings/components/card'
import { TenantSettingsSelect } from '@/features/tenant/components/tenant-settings-select'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import UnauthorizedContent from '@/components/environment/unauthorized-content'
import TableSkeleton from '@/components/skeletons/table-skeleton'

import type { TenantUser } from '@/features/tenant/types'
import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const TeamSettingsTable = lazy(
	() => import('@/features/tenant/components/team-list'),
)

const UserPanel = lazy(
	() => import('@/features/tenant/components/user/user-panel'),
)

const searchSchema = z.object({
	q: z.string().optional(),
	panel: z.string().optional(),
})

type Search = z.infer<typeof searchSchema>

export const Route = createFileRoute('/_dashboard/settings/tenant-settings/')({
	validateSearch: zodValidator(searchSchema),

	beforeLoad: () => roleAuthGuard(Roles.enum.Admin),

	component: TeamSettingsRoute,
	pendingComponent: TeamSettingsRouteSkeleton,
	errorComponent: TeamSettingsError,
})

function TeamSettingsRoute() {
	const [query, setQuery] = useState<string>('')

	const navigate = Route.useNavigate()

	const { createPanel, open, close } = usePanelActions()

	const handleSelectUser = (
		user: TenantUser | null,
		mode: 'edit' | 'create' = 'create',
	) => {
		const isEditing = mode === 'edit'

		createPanel({
			title: isEditing ? 'Edit User' : 'Create User',
			description: isEditing ? 'Update a user settings' : 'Create a new user',
			content: <UserPanel user={user} mode={mode} onSuccess={close} />,
			onClose: handleClose,
		})

		open()

		navigate({
			search: (old: Search) => ({
				...old,
				panel: encodeURIComponent(user?.username ?? 'new'),
			}),
		})
	}

	const handleClose = () => {
		navigate({
			search: (old: Search) => ({
				...old,
				panel: undefined,
			}),
		})
	}

	return (
		<>
			<div className="flex flex-col items-start justify-start gap-2">
				<p className="text-muted-foreground">Settings for:</p>
				<TenantSettingsSelect />
			</div>
			<SettingsCard>
				<SettingsCardTitle>Members</SettingsCardTitle>

				<Suspense fallback={<TeamSettingsRouteSkeleton />}>
					<TeamSettingsTable
						query={query}
						onQueryChange={setQuery}
						onSelectUser={handleSelectUser}
					/>
				</Suspense>
			</SettingsCard>
		</>
	)
}

function TeamSettingsRouteSkeleton() {
	return (
		<>
			<div className="flex flex-col items-start justify-start gap-2">
				<p className="text-muted-foreground">Settings for:</p>
				<TenantSettingsSelect />
			</div>
			<SettingsCard>
				<SettingsCardTitle>Members</SettingsCardTitle>

				<TableSkeleton />
			</SettingsCard>
		</>
	)
}

function TeamSettingsError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()

	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
