import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { ErrorBoundary } from '@sentry/react'
import { z } from 'zod'

import { actionQueries } from '@/features/actions/queries'
import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { graylogQueries } from '@/features/graylog/queries'
import { playbookQueries } from '@/features/playbooks/queries'
import { getCurrentTenant } from '@/features/user/store'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

const AutomationSettingsDetailsForm = lazy(
	() => import('@/features/graylog/automation-settings/component/details/form'),
)

const searchSchema = z.object({
	event_id: z.string().optional(),
	playbook_title: z.string().optional(),
	action_name: z.string().optional(),
})

export const Route = createFileRoute(
	'/_dashboard/graylog/automation-settings/new/',
)({
	validateSearch: zodValidator(searchSchema),

	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ context: { queryClient } }) => {
		const tenant_id = getCurrentTenant() as string

		queryClient.prefetchQuery(actionQueries.list(tenant_id))
		queryClient.prefetchQuery(graylogQueries.events(tenant_id))
		queryClient.prefetchQuery(playbookQueries.list(tenant_id))
	},

	component: AutomationSettingsEvent,
	pendingComponent: AutomationSettingsEventFallback,
})

function AutomationSettingsEvent() {
	return (
		<div className="flex w-full flex-col items-start justify-start gap-2">
			<title>Create New Automation | Graylog | BitLyft Air®</title>
			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<TableSkeleton rows={10} />}>
					<AutomationSettingsDetailsForm ruleId={null} />
				</Suspense>
			</ErrorBoundary>
		</div>
	)
}

function AutomationSettingsEventFallback() {
	return (
		<div className="flex w-full flex-row items-center justify-between">
			<Skeleton className="h-12 w-1/2" />
			<Skeleton className="h-12 w-1/2" />
		</div>
	)
}
