import { z } from 'zod'

export type ActivityDTO = {
	summary: Activity
	details: ActivityDetails
}

export type ActivityDetails = {
	context: {
		action_uuid: string
		adhoc?: boolean | null
		attempt_count?: number | null
		tenant_id: string
		time_scheduled: string
		time_started?: string | null
		time_completed?: string | null
		workflow_action_id: string
		workflow_instance_id: string
		workflow_name?: string
	}
	action_name: string
	action_output?: Record<string, any>
	error_info?: ActivityDetailsError | null
}

export type ActivityDetailsError = {
	error_code: string | null
	error_details: string | null
	error_message: string
	error_timestamp: string
}

export type Activity = {
	action_uuid?: string
	action_name?: string
	adhoc?: boolean
	canonical_id?: string
	display_name?: string
	status: ActivityStatus
	tenant?: string
	time_scheduled?: string
	time_started?: string
	time_completed?: string
	workflow_instance_id: string | null
	trigger_info?: {
		trigger_type: string
		workflow_instance_id: string
		workflow_name: string
		workflow_action_id: string
	}
}

const ACTION_ACTIVITY_STATUS = [
	'pending',
	'running',
	'cancelled',
	'success',
	'error',
] as const

export const ACTIVITY_STATUS = z.enum(ACTION_ACTIVITY_STATUS)

export type ActivityStatus = z.infer<typeof ACTIVITY_STATUS>
