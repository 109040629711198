import {
	createRootRouteWithContext,
	Outlet,
	redirect,
} from '@tanstack/react-router'

import { sentry } from '@/lib/sentry'

import { isUserAuthenticated } from '@/features/auth/queries'

import { Logo } from '@/components/environment/logo'
import { NotFoundContent } from '@/components/environment/not-found-content'
import { SectionTitle } from '@/components/environment/section'
import { Button } from '@/components/ui/button'

import type { QueryClient } from '@tanstack/react-query'
import type { XiorError } from 'xior'

/**
 * Root layout that wraps the entire application.
 */
export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
	{
		beforeLoad: async ({ context: { queryClient }, location }) => {
			const isRoot = location.pathname === '/'
			if (!isRoot) return

			const isAuthenticated = await isUserAuthenticated(queryClient)

			// If user is not authenticated, redirect to the logout page to make sure the user session is cleared
			if (!isAuthenticated) {
				throw redirect({
					to: '/logout',
				})
			}
		},

		component: RootLayout,
		errorComponent: RootLayoutError,
		notFoundComponent: NotFoundContent,
	},
)

function RootLayout() {
	return (
		<div className="flex h-screen w-full flex-row items-start justify-start overflow-hidden">
			<Outlet />
		</div>
	)
}

function RootLayoutError({ error }: { error: unknown }) {
	const err = error as XiorError

	sentry.captureException(err, {
		contexts: {
			response: err?.response?.data,
		},
		extra: {
			origin: 'root-layout-error',
			page: window.location.href,
			request: err?.request?.url,
		},
	})

	return (
		<div className="flex h-screen w-full flex-col items-center justify-center gap-4 overflow-hidden">
			<Logo className="size-16" />
			<SectionTitle className="my-0">Unexpected Error</SectionTitle>

			<p className="text-center text-muted-foreground">
				Error code: {err?.response?.status}
			</p>

			<pre className="max-w-screen-sm whitespace-break-spaces rounded-lg bg-foreground p-2 text-center">
				{JSON.stringify(err?.response?.data.error.message, null, 2)}
			</pre>

			<Button variant="outline" onClick={() => window.location.reload()}>
				Try Again
			</Button>
		</div>
	)
}
