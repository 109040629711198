import { env } from '@/env'

import packageJson from '../../package.json'

import type { ResourcesConfig } from 'aws-amplify'

// ENVIRONMENT
export const isDEV = import.meta.env.DEV
export const isPROD = import.meta.env.PROD
export const isMOCK = env.VITE_USE_MOCK_DATA
export const appVersion = packageJson.version

// API
export const API_URL = env.VITE_AIR_API_URL

// AMPLIFY/COGNITO
export const amplifyConfig: ResourcesConfig = {
	Auth: {
		Cognito: {
			signUpVerificationMethod: 'code',
			userPoolId: env.VITE_AWS_USER_POOLS_ID,
			userPoolClientId: env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
			loginWith: {
				email: true,
				phone: false,
				username: true,
			},
		},
	},
}
