type LogoProps = {
	className?: string
}

export const Logo = ({ className }: LogoProps) => {
	return (
		<svg
			viewBox="0 0 30 43"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g id="Group 27">
				<path
					id="Vector 6"
					d="M19.7571 5.6989L10.2441 0.466797V20.5871L19.7571 25.8192V5.6989Z"
					fill="#CEE2FF"
				/>
				<path
					id="Vector 7"
					d="M29.2688 20.1115L19.7559 25.8192V15.1172L29.2688 20.1115Z"
					fill="url(#paint0_linear_24495_4940)"
				/>
				<path
					id="Vector 8"
					d="M29.2688 20.1113V31.0512L19.7559 25.8191L29.2688 20.1113Z"
					fill="#2261DB"
				/>
				<path
					id="Union"
					d="M29.2692 31.0505L19.7563 25.8184L0.730469 37.2339L10.2434 42.466L29.2692 31.0505Z"
					fill="url(#paint1_linear_24495_4940)"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_24495_4940"
					x1="26.893"
					y1="15.3818"
					x2="19.9782"
					y2="25.8923"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#91BDFF" />
					<stop offset="1" stopColor="#CEE2FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_24495_4940"
					x1="24.9572"
					y1="26.7212"
					x2="10.5744"
					y2="44.4231"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#3F78E7" />
					<stop offset="1" stopColor="#CEE2FF" />
				</linearGradient>
			</defs>
		</svg>
	)
}
