import { lazy, Suspense, useState } from 'react'

import { createFileRoute } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { z } from 'zod'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { graylogQueries } from '@/features/graylog/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const AutomationSettingsTable = lazy(
	() => import('@/features/graylog/automation-settings/component/table'),
)
const AutomationSettingsSearch = lazy(
	() => import('@/features/graylog/automation-settings/component/search'),
)

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

const searchSchema = z.object({
	q: z.string().optional(),
})

export const Route = createFileRoute(
	'/_dashboard/graylog/automation-settings/',
)({
	validateSearch: zodValidator(searchSchema),

	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string

		await queryClient.ensureQueryData(graylogQueries.automationSettings(tenant))
	},

	component: AutomationSettingsRoute,
	errorComponent: AutomationSettingsError,
	pendingComponent: AutomationSettingsFallback,
})

function AutomationSettingsRoute() {
	const [query, setQuery] = useState<string>('')

	return (
		<div className="flex flex-col gap-4">
			<title>Automation Settings | Graylog | BitLyft Air®</title>
			<Suspense fallback={<Skeleton className="my-2 h-12 w-full" />}>
				<AutomationSettingsSearch query={query} onQueryChange={setQuery} />
			</Suspense>

			<Suspense fallback={<TableSkeleton rows={5} />}>
				<AutomationSettingsTable query={query} />
			</Suspense>
		</div>
	)
}

/**
 * Loading state for Remediation Actions
 */
function AutomationSettingsFallback() {
	return (
		<div className="flex flex-col gap-4">
			<TableSkeleton rows={5} />
		</div>
	)
}

/**
 * Error component for Remediation Actions
 */
function AutomationSettingsError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
