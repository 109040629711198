import { useEffect } from 'react'

import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'

/**
 * Hook to reset the query error boundary in a router component
 *
 * Should only be used in a router component since it depends on the router
 * to reset the query.
 */
export const useErrorBoundary = () => {
	const router = useRouter()
	const queryErrorResetBoundary = useQueryErrorResetBoundary()

	useEffect(() => {
		queryErrorResetBoundary.reset()
	}, [queryErrorResetBoundary.isReset])

	return {
		reset: router.invalidate,
		isReset: queryErrorResetBoundary.isReset,
	}
}
