import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { playbookQueries } from '@/features/playbooks/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import UnauthorizedContent from '@/components/environment/unauthorized-content'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { XiorError } from 'xior'

const PlaybookDetails = lazy(
	() => import('@/features/playbooks/components/details'),
)

export const Route = createFileRoute('/_dashboard/playbooks/$id')({
	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ params, context: { queryClient } }) => {
		const { id } = params
		const tenant = getCurrentTenant() as string

		queryClient.prefetchQuery(playbookQueries.detail(tenant, id))
	},

	component: RunPlaybookRoute,
	pendingComponent: RunPlaybookLoading,
	errorComponent: RunPlaybookError,
})

function RunPlaybookRoute() {
	const { id } = Route.useParams()

	return (
		<Section>
			<title>Playbook Details | BitLyft Air®</title>
			<SectionTitle>Playbook Details</SectionTitle>

			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<Skeleton className="h-full w-full" />}>
					<PlaybookDetails id={id} />
				</Suspense>
			</ErrorBoundary>
		</Section>
	)
}

function RunPlaybookLoading() {
	return (
		<Section>
			<title>Playbook Details | BitLyft Air®</title>
			<SectionTitle>Playbook Details</SectionTitle>
			<div className="flex w-full flex-col items-start justify-start">
				<TableSkeleton rows={10} />
			</div>
		</Section>
	)
}

function RunPlaybookError({ error }: { error: unknown }) {
	const { reset } = useErrorBoundary()

	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
